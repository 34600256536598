exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-cloud-services-tsx": () => import("./../../../src/pages/cloud-services.tsx" /* webpackChunkName: "component---src-pages-cloud-services-tsx" */),
  "component---src-pages-cyber-security-tsx": () => import("./../../../src/pages/cyber-security.tsx" /* webpackChunkName: "component---src-pages-cyber-security-tsx" */),
  "component---src-pages-disaster-recovery-tsx": () => import("./../../../src/pages/disaster-recovery.tsx" /* webpackChunkName: "component---src-pages-disaster-recovery-tsx" */),
  "component---src-pages-ethical-policy-tsx": () => import("./../../../src/pages/ethical-policy.tsx" /* webpackChunkName: "component---src-pages-ethical-policy-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-ict-maintenance-tsx": () => import("./../../../src/pages/ict-maintenance.tsx" /* webpackChunkName: "component---src-pages-ict-maintenance-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-project-services-tsx": () => import("./../../../src/pages/project-services.tsx" /* webpackChunkName: "component---src-pages-project-services-tsx" */),
  "component---src-pages-software-development-tsx": () => import("./../../../src/pages/software-development.tsx" /* webpackChunkName: "component---src-pages-software-development-tsx" */),
  "component---src-pages-telephony-tsx": () => import("./../../../src/pages/telephony.tsx" /* webpackChunkName: "component---src-pages-telephony-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */),
  "component---src-pages-trading-systems-tsx": () => import("./../../../src/pages/trading-systems.tsx" /* webpackChunkName: "component---src-pages-trading-systems-tsx" */)
}

